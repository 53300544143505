// Core
import { useState, useEffect } from 'react';

// Libraries
import { useToasts } from 'react-toast-notifications';
import _ from 'lodash';

// Utils
import { handleResponse } from '../utils/axios';

const useForm = (routeFunction, initialValues, initialTouched, onSuccess, onError, validateForm, rules) => {
    const [values, setValues] = useState(initialValues);
    const [touched, setTouched] = useState(initialTouched);
	const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [response, setResponse] = useState({});
    const [message, setMessage] = useState({});

    const { addToast } = useToasts();

    // Hook runs whenever server response variable changes to update form status message.
    useEffect(() => {
        let newMessage = {
        };

        // Display any server response message
        if (_.has(response, 'type')) {
            newMessage = {
                type: response.type,
                text: response.message
            };
            if (response.type === 'error') {
                if (response.status === 422) {
                    if (Object.keys(response.errors).length > 0) {
                        const errorCount = Object.keys(response.errors).length;
                        newMessage = {
                            type: 'error',
                            text: `There are ${errorCount} errors below, please correct them and try again.`
                        };
                    }
                } else {
                    newMessage = {
                        type: 'error',
                        text: `There was an error with that request.  Please contact us for help.`
                    };
                }  
            }
        }
        setMessage(newMessage);
    }, [response]);
    
    useEffect(
        () => {
            if (validateForm) {
                const validationErrors = validateForm(rules, values, touched, isSubmitClicked);
                setErrors(validationErrors);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [values]
    );

	const handleSubmit = async event => {
		if (event) {
            event.preventDefault();
        }

        setIsSubmitClicked(true);
		// Only validate if the validate function is used
		if (validateForm) {
            const validationErrors = validateForm(rules, values);
            setErrors(validationErrors);
            if (Object.keys(validationErrors).length > 0) {
                return false;
            }
		}
        setIsSubmitting(true);
        const response = await routeFunction(values);
        const handled = handleResponse(response);
        setResponse(handled);
        setIsSubmitting(false);
        if (handled.type === 'success') {
            showSuccessNotification(handled);
            onSuccess(handled);
        } else {
            setErrors(handled.errors);
            showErrorNotification(handled);
            onError(handled);
        }
    }

    const resetForm = () => {
        setValues(initialValues);
        setTouched(initialTouched);
        setMessage({});
        setErrors({});
    }

    const showSuccessNotification = (handled) => {
        let message = 'Completed Successfully';
        if (_.has(handled, 'message')) {
            message = handled.message;
        }
    
        addToast(message, { autoDismiss: true, appearance: 'success' });
    }

    const showErrorNotification = (handled) => {
        let valueKeys = Object.keys(values); 
        let errorKeys = Object.keys(handled.errors); 

        let messages = [];
        if (errorKeys.length > 0) {
            let errorsWithoutField = errorKeys.filter((item) => !valueKeys.includes(item));
            if (errorsWithoutField.length > 0) {
                messages = errorsWithoutField.map((item) => {
                    return handled.errors[item];
                });    
            }
        }

        if (messages.length === 0) {
            if (_.has(handled, 'message')) {
                messages.push(handled.message);
            }
        }

        if (messages.length === 0) {
            messages.push('There was a problem with that request');
        }
        messages.map((message) => addToast(message, { autoDismiss: true, appearance: 'error' }))
    }

	const handleChange = event => {
        event.persist();
        changeValue(event.target.name, event.target.value);
	}

    const handleTypeaheadChange = (name, value) => {
        changeValue(name, value);
	}

    const changeValue = (name, value) => {
        setValues(values => ({
			...values,
			[name]: value,
        }));
        setTouched(touched => ({
			...touched,
			[name]: true,
        }));
    }

	return {
		handleChange,
        handleTypeaheadChange,
		handleSubmit,
        resetForm,
		values,
        setValues,
        errors,
        response,
        isSubmitting,
        message
	}
}

export default useForm