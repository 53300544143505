// Core
import React from 'react';
import Router from './router/index';

// Libraries
import { ToastProvider } from 'react-toast-notifications';

// Contexts
import AppContextProvider from './contexts/AppContext';
import BoardContextProvider from './contexts/BoardContext';
import BoardsContextProvider from './contexts/BoardsContext';
import AuthContextProvider from './contexts/AuthContext';

// Containers
import AuthApp from './containers/AuthApp';

// CSS
import './App.scss';

function App() {
  return (
    <div className="App h-100">
        <ToastProvider>
        <AppContextProvider>
            <AuthContextProvider>
                <BoardsContextProvider>
                    <BoardContextProvider>
                    <AuthApp>
                        <Router />
                    </AuthApp>
                    </BoardContextProvider>
                </BoardsContextProvider>
            </AuthContextProvider>
        </AppContextProvider>
        </ToastProvider>
    </div>
  );
}

export default App;
