// Core
import React, { createContext, useReducer } from 'react';

// API's
import Board from "../apis/Board";

// Utils
import { handleResponse } from '../utils/axios';

// Reducers
import { BoardsReducer } from '../reducers/BoardsReducer';

export const BoardsContext = createContext();

const BoardsContextProvider = (props) => {
    const [boards, dispatch] = useReducer(BoardsReducer, {
        boards: [],
        isLoaded: false
    });
    
    const getBoards = async () => {
        const response = await Board.index();
        const handled = handleResponse(response);
        if (handled.type === 'success') {
            setBoards(handled.data);
            dispatch({
                type: 'SET_BOARDS_LOADED'
            });   
        }
    };

    const setBoards = (boards) => {
        dispatch({
            type: 'SET_BOARDS',
            payload: boards
        });
    };

    const contextValue = {
        boards,
        setBoards,
        getBoards,
        dispatch
    };

    return (
        <BoardsContext.Provider value={contextValue}>
            { props.children }
        </BoardsContext.Provider>
    );
}

export default BoardsContextProvider;