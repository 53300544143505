// Core
import React from "react";

function FullPageLayout({ title, subtitle, children }) {
    return (  
        <>
        <main className="py-4">
        <div className="container full-page-layout">
            {children}
        </div>
        </main>
        </>
    );
}

export default FullPageLayout;