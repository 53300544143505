// Core
import React, { createContext, useReducer } from 'react';

// Reducers
import { BoardReducer } from '../reducers/BoardReducer';

export const BoardContext = createContext();

const BoardContextProvider = (props) => {
    const [board, dispatch] = useReducer(BoardReducer, {});
    
    const setBoard = (board) => {
        dispatch({
            type: 'SET_BOARD',
            payload: board
        });
    };

    const contextValue = {
        board,
        setBoard
    };

    return (
        <BoardContext.Provider value={contextValue}>
            { props.children }
        </BoardContext.Provider>
    );
}

export default BoardContextProvider;