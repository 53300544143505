// Core
import React from 'react';

// Libraries
import { Alert } from 'react-bootstrap';
import _ from 'lodash';

const StationAlerts = ({ board }) => {
    let alerts = [];
    if (_.has(board, 'routes')) {
        board.routes.forEach((route) => {
            if (_.has(route, 'station_board.nrccMessages.message')) {
                let m = route.station_board.nrccMessages.message[0]['_'];
                alerts.push(m);
            }
        });
        
        alerts = [...new Set(alerts)];
    }
    return (
        <div>
        {alerts.map(
            (message, messageKey) => {
                return (
                    <Alert key={messageKey} variant="warning">
                        <div dangerouslySetInnerHTML={{__html: message}}></div>
                    </Alert>
                );
            }
        )}
        </div>
    );
}
 
export default StationAlerts;