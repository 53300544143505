// Core
import React, { useEffect, useState, useContext } from 'react';

// Libraries
import { Row, Col, Form } from 'react-bootstrap';

// API's
import BoardRoute from "../../apis/BoardRoute";

// Utils
import { validateForm } from '../../utils/validation';

// Hooks
import useForm from '../../hooks/useForm';

// Contexts
import { BoardContext } from '../../contexts/BoardContext';

// Components
import TextInputGroup from '../../components/TextInputGroup';
import SubmitButton from '../../components/SubmitButton';
import TypeaheadInput from '../../components/TypeaheadInput';

// CSS
import 'react-bootstrap-typeahead/css/Typeahead.css';

// Data
import stations from '../../data/stations.json';

let stationList = [];

const rules = {
    start_station_id: [
        {
            name: 'required'
        },
    ],
    end_station_id: [
        {
            name: 'required'
        },
    ],
    mins_to_station: [
        {
            name: 'integer'
        }
    ]
};

function AddRouteForm({ code }) {
    useEffect(() => {
        stationList = stations.RECORDS;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);  // Run only on first mounted

    // Inital variables
    const initialValues = {
        start_station_id: '',
        end_station_id: '',
        mins_to_station: ''
    };
    
    const initialTouched = {
        start_station_id: false,
        end_station_id: false,
        mins_to_station: false
    };

    // Hooks
    const { board, setBoard } = useContext(BoardContext);

    const [startStationIdSelections, setStartStationIdSelections] = useState([]);
    const [endStationIdSelections, setEndStationIdSelections] = useState([]);

    // Custom hook
    const route = async (values) => { return await BoardRoute.create(code, values);}
    const handleSuccess = async (handled) => {
        setStartStationIdSelections([]);
        setEndStationIdSelections([]);

        setBoard({
            ...board,
            routes: handled.data
        });
        resetForm();
    }

    const handleError = async (handled) => {
        //
    }
    
    const {
        values,
        // eslint-disable-next-line
        touched,
        errors,
        // eslint-disable-next-line
        response,
        isSubmitting,
        handleChange,
        handleTypeaheadChange,
        handleSubmit,
        resetForm
    } = useForm(
        route,
        initialValues,
        initialTouched,
        handleSuccess,
        handleError,
        validateForm,
        rules
    );

    const isStartStationIdError = errors.start_station_id && errors.start_station_id.length > 0;
    const handleStartStationChange = (selected) => {
        handleStationChange(selected, 'start_station_id');
    };

    const isEndStationIdError = errors.end_station_id && errors.end_station_id.length > 0;
    const handleEndStationChange = (selected) => {
        handleStationChange(selected, 'end_station_id');
    };

    const handleStationChange = (selected, name) => {
        let selectedValue = '';
        if (selected.length > 0) {
            const selectedStation = selected[0];
            selectedValue = selectedStation.id;
        }
        handleTypeaheadChange(name, selectedValue);
    }

    return (
        <Form onSubmit={handleSubmit} noValidate>
            <Row>
                <Col xs={12} lg={8}>
                    <Form.Group>
                        <TypeaheadInput
                            placeholder="The station you're leaving from"
                            label="Start Station"
                            defaultSelected={[""]}
                            labelKey="name"
                            id="start_station_id"
                            name="start_station_id"
                            selected={startStationIdSelections}
                            options={stationList}
                            error={isStartStationIdError ? errors.start_station_id[0] : null}
                            onChange={(x) => {
                                handleStartStationChange(x);
                                setStartStationIdSelections(x)}}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} lg={4} className="form-group">
                    <TextInputGroup 
                        placeholder="Time in mins"
                        type="number"
                        label="Time to station"
                        name="mins_to_station"
                        value={values.mins_to_station} 
                        error={errors.mins_to_station && errors.mins_to_station.length > 0 ? errors.mins_to_station[0] : null} 
                        handleChange={handleChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={8}>
                    <Form.Group>
                        <TypeaheadInput
                            placeholder="The station you're going to"
                            label="Destination Station"
                            defaultSelected={[""]}
                            labelKey="name"
                            id="end_station_id"
                            name="end_station_id"
                            selected={endStationIdSelections}
                            options={stationList}
                            error={isEndStationIdError ? errors.end_station_id[0] : null}
                            onChange={(x) => {
                                handleEndStationChange(x);
                                setEndStationIdSelections(x)}}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <div className="form-group mb-0">
                <SubmitButton buttonText="Add route" loadingText="Loading" isLoading={isSubmitting}/>
            </div>
        </Form>
    );
}

export default AddRouteForm;