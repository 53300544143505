// Core
import React from 'react';

// Libraries
import { Switch, BrowserRouter, Route } from 'react-router-dom';

// Containers
import PrivateRoute from '../containers/PrivateRoute';

// Pages
import Home from '../pages/Home';
import Welcome from '../pages/Welcome';
import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import Register from '../pages/Register';
import CreateBoard from '../pages/CreateBoard';
import EditBoard from '../pages/EditBoard';
import ListBoards from '../pages/ListBoards';
import ShowBoard from '../pages/ShowBoard';

function Router(props) {
    return (
        <>
            <BrowserRouter>
                <Switch>
                    <PrivateRoute path="/home" component={Home} />
                    <Route exact path="/" component={Welcome} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/forgot-password" component={ForgotPassword} />
                    <Route path="/register" component={Register} />
                    <Route exact path="/reset-password/:token" component={ResetPassword}/>
                    <Route exact path="/departure-boards/:code/edit" component={EditBoard}/>
                    <Route exact path="/departure-boards/create" component={CreateBoard}/>
                    <Route exact path="/departure-boards/:code" component={ShowBoard}/>
                    <PrivateRoute exact path="/departure-boards" component={ListBoards}/>
                </Switch>
            </BrowserRouter>
        </>
    );
}

export default Router;