// Core
import React from 'react';

// Libraries
import { Card, Table } from 'react-bootstrap';
import _ from 'lodash';
import { format } from "date-fns-tz";
import { differenceInSeconds, parse, parseISO } from "date-fns";

const TrainService = ({ route, date, time, offset }) => {
    const offsetValue = offset ? offset : 0;
    const currentDate = `${date} ${time}`;
    const services = _.has(route, "station_board.trainServices.service")
    ? route.station_board.trainServices.service.map((service, serviceKey) => {
        const serviceDate = `${date} ${service.std}:00`;
        let diff = differenceInSeconds(
            parse(serviceDate, 'yyyy-MM-dd HH:mm:ss', new Date()),
            parse(currentDate, 'yyyy-MM-dd HH:mm:ss', new Date())
        );
        if (Number.isInteger(diff)) {
            diff = Math.ceil(diff/60);
        }
        if (Number.isInteger(diff) && Number.isInteger(offsetValue)) {
            diff -= offsetValue;;
        }

        if (diff < 0) {
            diff = 0;
        }
        
        return (
            <tr key={serviceKey}>
                <td>{service.std}</td>
                <td className={service.etd === 'Cancelled' ? 'text-danger' : 'text-dark'}>{service.etd}</td>
                <td>{service.platform}</td>
                <td className={diff && diff > 0 ? 'text-success' : 'text-danger'}>
                    {diff}
                </td>
            </tr>
        )}) 
    : null;

    let generatedAt = null;

    if (_.has(route, 'station_board.generatedAt')) {
        generatedAt = parseISO(route.station_board.generatedAt);
        generatedAt = format(generatedAt, 'yyyy-MM-dd HH:mm:ss')
    }

    return (
        <Card>
            <Card.Body>
            <h4>{route.start_station.name} &gt; {route.end_station.name}</h4>
            {services
            ? <Table>
                <thead>
                    <tr>
                        <th className="border-top-0">Due</th>
                        <th className="border-top-0">Expected</th>
                        <th className="border-top-0">Plat</th>
                        <th className="border-top-0">Min</th>
                    </tr>
                </thead>
                <tbody>
                { services }
                </tbody>
            </Table>
            : <p>There are no trains due in the next two hours</p>}
            </Card.Body>
            <Card.Footer className="text-muted">
                <div>
                    <div><small>Generated At</small></div>
                    <div><small>{generatedAt}</small></div>
                </div>
            </Card.Footer>
        </Card>
    );
}
 
export default TrainService;