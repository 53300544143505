// Core
import { useState } from 'react';

// Libraries
import { useToasts } from 'react-toast-notifications';

// Utils
import { handleResponse, getErrorMessages, getSuccessMessage } from '../utils/axios';

const useDelete = (routeFunction, onSuccess, onError) => {

	const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [response, setResponse] = useState({});
    const [message] = useState({});

    const { addToast } = useToasts();
    const showSuccessNotification = (handled) => {
        const message = getSuccessMessage(handled);
        addToast(message, { autoDismiss: true, appearance: 'success' });
    
    };
    
    const showErrorNotification = (handled) => {
        const messages = getErrorMessages(handled);
        messages.map((message) => addToast(message, { autoDismiss: true, appearance: 'error' }));
    };
    
    const handleSubmit = async () => {
        setIsSubmitting(true);
        const response = await routeFunction();
        const handled = handleResponse(response);
        setResponse(handled);
        if (handled.type === 'success') {
            showSuccessNotification(handled);
            onSuccess(handled);
        } else {
            setErrors(handled.errors);
            showErrorNotification(handled);
            onError(handled);
        }
        setIsSubmitting(false);
    };

    return {
		handleSubmit,
        errors,
        response,
        isSubmitting,
        message
	};
}

export default useDelete