// Core
import React, { useEffect, useContext, useState } from 'react';

// Libraries
import { useParams } from "react-router";
import { useHistory, Link } from "react-router-dom";
import { Row, Col, Alert, ListGroup, Card } from 'react-bootstrap';

// API's
import Board from "../apis/Board";
import BoardRoute from "../apis/BoardRoute";

//Utils
import { handleResponse } from '../utils/axios';

// Contexts
import { BoardContext } from '../contexts/BoardContext';
import { AuthContext } from '../contexts/AuthContext';

// Containers
import PageLoader from '../containers/layout/PageLoader';
import AdminLayout from '../containers/layout/AdminLayout';

// Components
import DeleteButton from '../components/DeleteButton';
import AddRouteForm from '../components/Forms/AddRouteForm';
import EditBoardForm from '../components/Forms/EditBoardForm';

function EditBoard(props) {
    const baseURL = process.env.REACT_APP_URL;
    const history = useHistory();
    let { code } = useParams();
    const { auth } = useContext(AuthContext);
    const { board, setBoard } = useContext(BoardContext);
    const isLoggedIn = auth.isLoggedIn && auth.user;
    const [pageLoadFailed, setPageLoadFailed] = useState(false);

    useEffect(() => {
        async function fetchData() {
            const response = await Board.show(code);
            const handled = handleResponse(response);
            if (handled.type === 'success') {
                setBoard(handled.data);   
            } else {
                setPageLoadFailed(true);
            }
        }
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])  //Run only on first mounted

    const handleSuccess = async (handled) => {
        setBoard({
            ...board,
            routes: handled.data
        });
    }

    const handleDeleteBoardSuccess = async (handled) => {
        history.push('/departure-boards');
    }
    const hasRoutes = board.routes && board.routes.length > 0;
    
    return (
        <PageLoader>
            {pageLoadFailed
            ? <div class="container d-flex h-100 justify-content-center">
            <div class="row justify-content-center align-self-center text-center">
                <div class="font-weight-light h1 text-center">
                    Sorry, the page you are looking for could not be found.                </div>
            </div>
        </div>
            
            : <AdminLayout title={board.name} subtitle="Add and modify your routes and customise your board.">
                <div className="mb-3">
                    <Alert variant="info" className="text-center">
                        {board.routes && board.routes.length > 0
                        ? <>
                            <strong>This is your unique link to your board. Save it! Bookmark it! Share it!</strong><br />:
                            <Link to={'/departure-boards/' + code} target="_blank">{baseURL}/departure-boards/{code}</Link>
                        </>
                        : <h5 className="mt-2"><strong>To get started add your first route to the departure board</strong></h5>}
                    </Alert>
                    <Link to={'/departure-boards/' + code} target="_blank">View my departure board</Link>
                </div>
                <Row>
                    <Col xs={12} lg={8}>
                        { hasRoutes
                        ? <ListGroup className="mb-4">
                            {board.routes ? board.routes.map((route, id) => {
                                return (
                                    <ListGroup.Item key={id} className="list-group-item-action">
                                        <div className="d-flex w-100 justify-content-between align-items-center">
                                            <div>
                                                <h5 className="mb-0">{route.start_station.name} > {route.end_station.name}</h5> 
                                                {route.mins_to_station 
                                                ? <p className="mb-1 small">Time for you to get to {route.start_station.name}: {route.mins_to_station} minutes</p>
                                                : null}
                                            </div>
                                            <DeleteButton
                                                onSuccess={handleSuccess}
                                                onError={()=>{}}
                                                routeFunction={
                                                    async () => { 
                                                        return await BoardRoute.delete(route.id);
                                                    }}
                                            />
                                        </div>
                                    </ListGroup.Item>
                                )
                            }) : null}
                        </ListGroup>
                        : null}

                        <Card className="mb-4">
                            <Card.Body>
                                <h4>Add {board.routes && board.routes.length > 0 ? 'another' : 'your first'} route</h4>
                                <p className="mb-4 small">You can save up to four routes</p>
                                <AddRouteForm code={code} />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} lg={4}>
                        {!board.is_claimed
                        ? <Alert variant="warning">
                        {isLoggedIn 
                            ? <>This departure board can be edited by anyone you share it with. Open the settings below to claim and protect it.</>
                            :  <>This departure board can be edited by anyone you share it with. <Link to="/register">Register now</Link> to claim and protect it.</>}
                        </Alert>
                        : null}
                        <Card>
                            <Card.Body>
                                <h4>Settings</h4>
                                <EditBoardForm code={code} />
                                <div className="mt-5">
                                    <DeleteButton
                                        onSuccess={handleDeleteBoardSuccess}
                                        onError={()=>{}}
                                        routeFunction={
                                            async () => { 
                                                return await Board.delete(board.code);
                                            }}
                                    />
                                    <small className="text-muted d-block mt-2">This will permanently delete this departure board</small>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </AdminLayout> }
        </PageLoader>
    );
}

export default EditBoard;