// Core
import React from 'react';

// Libraries
import { Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

// CSS
import 'react-bootstrap-typeahead/css/Typeahead.css';

function TypeaheadInput({ selected, placeholder, label, name, value, error, onChange, options }) {
    return (
        <>
            <Form.Label htmlFor={name} className="col-form-label">{label}</Form.Label>
            <Typeahead
                placeholder={placeholder}
                defaultSelected={[""]}
                labelKey="name"
                className={error ? 'is-invalid' : ''}
                clearButton
                maxResults={5}
                paginate={false}
                id={name}
                name={name}
                isInvalid={error ? true : false}
                options={options}
                onChange={onChange}
                selected={selected}
            />
            { error ?
            <Form.Control.Feedback type="invalid">
                <strong>{error}</strong>
            </Form.Control.Feedback> : null}
        </>
    );
}

export default TypeaheadInput;