// Core
import React from "react";

// Components
import Navigation from "../../components/Navigation";

function AdminLayout({ title, subtitle, children }) {
    return (  
        <>
        <Navigation />
        <main className="py-4">
        <div className="container admin-layout">
            <div className="mb-4">
                <h1>{title}</h1>
                {subtitle ? <p className="lead">{subtitle}</p> : null}
            </div>
            {children}
        </div>
        </main>
        </>
    );
}

export default AdminLayout;