export const BoardsReducer = (state, action) => {
    switch(action.type) {
        case 'SET_BOARDS':
            return {
                ...state,
                boards:   [...action.payload]
            };
        case 'SET_BOARDS_LOADED':
            return {
                ...state,
                isLoaded: true
            };
        case 'SET_BOARDS_NOT_LOADED':
            return {
                ...state,
                isLoaded: false
            };
        default:
            return state;
    }
}