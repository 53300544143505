// Core
import React from 'react';

//Libraries
import { Row, Col, Card } from 'react-bootstrap';

// Containers
import PageLoader from '../containers/layout/PageLoader';
import AdminLayout from '../containers/layout/AdminLayout';

// Components
import CreateBoardForm from '../components/Forms/CreateBoardForm';

function CreateBoard(props) {
    return (
        <PageLoader>
            <AdminLayout
                title="Create a new departure board"
                subtitle="To get started enter a name for your board, you will add your routes next"
            >
                <Row className="justify-content-center">
                    <Col xs={12} lg={8}>
                        <Card className="mb-4">
                            <Card.Body>
                                <CreateBoardForm />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </AdminLayout>
        </PageLoader>
    );
}

export default CreateBoard;