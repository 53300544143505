// Core
import React, { useEffect, useContext } from 'react';

// Libraries
import { Row, Col, Form } from 'react-bootstrap';
import _ from 'lodash';

// API's
import Board from "../../apis/Board";

// Utils
import { validateForm } from '../../utils/validation';

// Hooks
import useForm from '../../hooks/useForm';

// Contexts
import { AuthContext } from '../../contexts/AuthContext';
import { BoardContext } from '../../contexts/BoardContext';

// Components
import TextInput from '../../components/TextInput';
import SubmitButton from '../../components/SubmitButton';

const rules = {
    name: [
        {
            name: 'required'
        },
    ]
};

function EditBoardForm({ code }) {

    // Inital variables
    const initialValues = {
        name: '',
        is_auto_update: false,
        is_claimed: false
    }
    
    const initialTouched = {
        name: false,
        is_auto_update: false,
        is_claimed: false
    }

    // Hooks
    const { auth } = useContext(AuthContext);
    const { board, setBoard } = useContext(BoardContext);

    const isLoggedIn = auth.isLoggedIn && _.has(auth, 'user') && !_.isEmpty(auth.user);
    const isOwner = isLoggedIn && board.user && board.user.id === auth.user.id;
    const isClaimable = isLoggedIn && (board.is_claimed === false || isOwner);

    // Custom hook
    const route = async (values) => { return await Board.update(code, values);}
    const handleSuccess = async (handled) => {
        setBoard({
            ...handled.data
        });
        resetForm();
    }

    const handleError = async (handled) => {
        //
    }
    
    const {
        values,
        setValues,
        // eslint-disable-next-line
        touched,
        errors,
        // eslint-disable-next-line
        response,
        isSubmitting,
        handleChange,
        handleSubmit,
        resetForm
    } = useForm(
        route,
        initialValues,
        initialTouched,
        handleSuccess,
        handleError,
        validateForm,
        rules
    );

    useEffect(() => {
        function fetchData() {
            setValues({
                ...values,
                name: board.name,
                is_auto_update: board.is_auto_update,
                is_claimed: board.is_claimed
            });
        };

        fetchData();
    // eslint-disable-next-line
    }, [board, setValues]);

    return (
        <Form onSubmit={handleSubmit} noValidate>
            <input type="hidden" name="auto_update" value="false" />
            <Row>
                <Col xs={12} className="form-group">
                    <TextInput
                        placeholder="Enter board name"
                        type="text"
                        label="Board Name"
                        name="name"
                        value={values.name} 
                        error={errors.name && errors.name.length > 0 ? errors.name[0] : null} 
                        help="Give your board a name, for example my route to work"
                        handleChange={handleChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="form-group">
                    <Form.Switch
                        label="Auto update"
                        id="is_auto_update"
                        name="is_auto_update"
                        checked={values.is_auto_update}
                        onChange={()=> setValues({...values, is_auto_update: !values.is_auto_update})}
                    />
                    <Form.Text id="isAutoUpdateHelpBlock" muted>
                    If you want the board can update the train times automatically, great for permanent dashboards!
                    </Form.Text>
                </Col>
            </Row>

            {isClaimable
            ? <Row>
                <Col xs={12} className="form-group">
                    <Form.Switch
                        label="Claim this board"
                        id="is_claimed"
                        name="is_claimed"
                        checked={values.is_claimed}
                        onChange={()=> setValues({...values, is_claimed: !values.is_claimed})}
                    />
                    <Form.Text id="isClaimedHelpBlock" muted>
                    This board can be edited by anyone you share it with. If you claim it you will need to login to make changes.
                    </Form.Text>
                </Col>
            </Row>
            : null}

            <div className="form-group mb-0">
                <SubmitButton buttonText="Update Board" loadingText="Loading" isLoading={isSubmitting}/>
                {/*<Link className="btn btn-link" to="/forgot-password">Forgot your password</Link>*/}
            </div>
        </Form>
    );
}

export default EditBoardForm;