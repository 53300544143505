import image1 from '../../src/image/powered_by_nre.png';

const Footer = () => {
    return (
        <footer className="container py-5">
            <div className="row">
                <div className="col">
                    <small className="d-block mb-3 text-muted">© 2021 Created by <a href="http://davidlobo.co.uk" target="_blank" rel="noreferrer">David Lobo</a></small>
                </div>
                <div className="col">
                    <div className="text-right">
                        <img alt="National Rail Enquiries Logo" src={image1} width="120" height="25" style={{opacity: 0.7}} />
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;