// Core
import React, { useEffect, useState, useContext } from 'react';

// Libraries
import { useParams } from "react-router";
import { Row, Col, Alert, Button } from 'react-bootstrap';
import { format, utcToZonedTime } from "date-fns-tz";
import { parseISO } from "date-fns";
import _ from 'lodash';

// API's
import DepartureBoard from "../apis/DepartureBoard";

// Utils
import { handleResponse } from '../utils/axios';

// Contexts
import { BoardContext } from '../contexts/BoardContext';
import { AppContext } from '../contexts/AppContext';

// Containers
import PageLoader from '../containers/layout/PageLoader';
import FullPageLayout from '../containers/layout/FullPageLayout';

// Components
import TrainService from '../components/TrainService';
import StationAlerts from '../components/StationAlerts';

function ShowBoard(props) {
    const baseURL = process.env.REACT_APP_URL;

    let { code } = useParams();

    const [date, setDate] = useState(new Date());
    const { board, setBoard } = useContext(BoardContext);
    const { dispatch } = useContext(AppContext);

    const timeZone = 'Europe/London'; // Let's see what time it is Down Under
    const timeInLondon = utcToZonedTime(date, timeZone);
    const currentISODate = `${format(timeInLondon, 'yyyy-MM-dd')}`;
    const currentISOTime = `${format(timeInLondon, 'HH:mm:ss')}`;
    const currentDay = format(date, 'eee do MMMM');
    const currentTime = format(date, 'HH:mm');

    let timer;
    const clearCurrentTime = () => {
        clearInterval(timer);
    }
    const startCurrentTime = () => {
        timer = setInterval(() => {
            setDate(new Date());
        }, 10000);
    }

    async function fetchData() {
        dispatch({ type: 'PAGE_LOADING_START' });
        const response = await DepartureBoard.show(code);
        const handled = handleResponse(response);
        if (handled.type === 'success') {

            setBoard(handled.data);
        }
        dispatch({ type: 'PAGE_LOADING_STOP' });
    }
    useEffect(() => {
        fetchData();
        startCurrentTime();

        return () => {
            clearCurrentTime();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])  //Run only on first mounted

    let requestedAt = null;

    if (_.has(board, 'requested_at')) {
        requestedAt = parseISO(board.requested_at);
        requestedAt = format(requestedAt, 'yyyy-MM-dd HH:mm:ss')
    }

    return (
        <PageLoader>
            <FullPageLayout>
                <Row>
                    <Col xs={12}>
                        <Alert variant="primary-tb" className="text-white">
                            <span className="h2"><span id="time">{currentTime}</span></span>
                            <span className="h5 ml-1"><span id="date">{currentDay}</span></span>
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    {board.routes
                        ? board.routes.map((route, routeKey) => {
                            return (
                                <Col key={routeKey} sm={12} lg={true} className="mb-3">
                                    <TrainService
                                        route={route}
                                        date={currentISODate}
                                        time={currentISOTime}
                                        offset={route.mins_to_station}
                                    />
                                </Col>
                            )
                        })
                        : null}
                </Row>
                <Row>
                    <Col>
                        <StationAlerts board={board} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button onClick={() => { fetchData() }} variant="primary" size="md">Refresh</Button>
                        <p className="mt-2"><small>Last refreshed: {requestedAt}</small></p>
                    </Col>
                </Row>
            </FullPageLayout>
        </PageLoader>
    );
}

export default ShowBoard;