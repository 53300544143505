// Core
import React, { useContext } from "react";

// Contexts
import { AppContext } from '../../contexts/AppContext';

// Components
import Loader from '../../components/Loader';

function PageLoader({ children }) {
    const { app } = useContext(AppContext);
    if (app.pageLoading) {
        return <Loader />;
    }
    return (
        <>  
            {children}
        </>
    );
}

export default PageLoader;