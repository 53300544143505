
// Core
import React from 'react';

// Libraries
import { Button } from 'react-bootstrap';

// Hooks
import useDelete from '../hooks/useDelete';

function DeleteButton({ routeFunction, onSuccess, onError }) {
    const {
        // eslint-disable-next-line
        response,
        handleSubmit,
    } = useDelete(
        routeFunction,
        onSuccess,
        onError
    );

    return (
        <Button onClick={handleSubmit} variant="outline-danger">Delete</Button>
    );
}

export default DeleteButton;