// Core
import React from "react";

// Components
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";

function MainLayout({ children }) {
    return (  
        <>
        <Navigation />
        <main>
        <div className="container layout">
            {children}
        </div>
        </main>
        <Footer />
        </>
    );
}

export default MainLayout;