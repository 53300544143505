import Api from "./Api";
import Csrf from "./Csrf";

const Board = {
    async index(code) {
        await Csrf.getCookie();
        return Api.get(`/boards`);
    },

    async show(code) {
        return Api.get(`/boards/${code}`);
    },

    async create(form) {
        await Csrf.getCookie();

        return Api.post("/boards", form);
    },

    async update(code, form) {
        await Csrf.getCookie();
        form['_method'] = 'PUT';
        return Api.post(`/boards/${code}`, form);
    },

    async delete(code) {
        await Csrf.getCookie();
        const form = {};
        form['_method'] = 'DELETE';
        return Api.post(`/boards/${code}`, form);
    },
};

export default Board;