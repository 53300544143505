// Core
import React from 'react';

// Libraries
import { Link } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faSync, faTrain } from '@fortawesome/free-solid-svg-icons';

// Containers
import MainLayout from '../containers/layout/MainLayout';

// Images
import image1 from '../image/departure_board.png';
import image2 from '../image/station_alerts.png';
import image3 from '../image/departure_boards.png';

function Welcome(props) {
    return (
        <MainLayout>
            <div className="marketing-page">
            <section className="hero">
                <Container>
                    <Row className="align-items-center">
                        <Col lg={6}>
                            <h1 className="hero-heading">Get the train times you need when you need them</h1>
                            <p className="lead mt-5 font-weight-light">With TrainBoards you can create custom train departure boards, so you never miss a train again!</p>
                            <Link className="btn btn-primary btn-lg d-block d-md-inline-block mb-lg-3 my-5" to="/departure-boards/create">Create your board</Link>
                        </Col>
                        <Col lg={6}>
                            <div className="device-wrapper d-flex justify-content-center">
                            <img src={image1} alt="..." className="img-fluid" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="features">
                <Container>
                <div className="section-header text-center"><span className="section-header-title">Features</span>
                    <h2 className="h1">Live Train Data </h2>
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <p className="lead">TrainBoards uses Network Rail Train API ensuring train times are always delivered to you at the perfect moment.</p>
                        </div>
                    </div>
                </div>
                <Row className="mt-5 text-center">
                    <Col lg={4}>
                    <div className="features-item mb-5 mb-lg-0">
                        <div className="gradient-icon gradient-1">
                            <FontAwesomeIcon color="white" size="1x" icon={faClock} />
                        </div>
                        <h3 className="h5">Avoid Delays</h3>
                        <p>Trains are often late!  Instantly see the expected arrival time and platform number.</p>
                    </div>
                    </Col>
                    <Col lg={4}>
                        <div className="features-item mb-5 mb-lg-0">
                            <div className="gradient-icon gradient-2">
                                <FontAwesomeIcon color="white" size="1x" icon={faTrain} />
                            </div>
                            <h3 className="h5">Multiple Routes</h3>
                            <p>Add up to 4 routes to a single board.  So you can decide which train is your best option.</p>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="features-item mb-0">
                            <div className="gradient-icon gradient-3">
                                <FontAwesomeIcon color="white" size="1x" icon={faSync} />
                            </div>
                            <h3 className="h5">Auto Update</h3>
                            <p>Your board can be set to auto-update, then you can be sure the info you are seeing is accurate.</p>
                        </div>
                    </Col>
                </Row>
                </Container>
            </section>
            <section className="padding-big">
                <Container>
                    <Row className="align-items-center">
                        <Col lg={6}>
                            <div className="device-wrapper d-flex justify-content-center mb-lg-0 mb-5">
                            <img src={image2} alt="..." className="img-fluid" />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <h2>Cancelled Train and Station Alerts</h2>
                            <p className="lead mt-5 font-weight-light">The latest train and station information is displayed relevant to your journey.</p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="">
                <Container>
                    <Row className="align-items-center">
                        <Col lg={6}>
                            <h2>Keep all your boards in one place</h2>
                            <p className="lead mt-5 font-weight-light">Register to claim and store all your boards in your account.</p>
                            <Link className="btn btn-primary btn-lg d-block d-md-inline-block mb-lg-3 my-5" to="/register">Register now</Link>
                        </Col>
                        <Col lg={6}>
                            <div className="device-wrapper d-flex justify-content-center">
                            <img src={image3} alt="..." className="img-fluid" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            </div>
        </MainLayout>
    );
}

export default Welcome;