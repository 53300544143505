// Core
import React from "react";

// Components
import Navigation from "../../components/Navigation";

function AuthLayout({ title, subtitle, children }) {
    return ( 
        <>
        <Navigation />
        <main className="py-4">
        <div className="container auth-layout">
            <div className="mb-4">
                <h1>{title}</h1>
                {subtitle ? <p className="lead">{subtitle}</p> : null}
            </div>
            <div className="row justify-content-center">
                <div className="col-12 col-lg-6 mt-3 mb-5">
                {children}
                </div>
            </div>
        </div>
        </main>
        </>
    );
}

export default AuthLayout;