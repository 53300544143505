// Core
import React, { useEffect, useContext } from 'react';

// Libraries
import { useHistory, Link } from "react-router-dom";
import { Row, Col, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

// Contexts
import { BoardsContext } from '../contexts/BoardsContext';

// Containers
import PageLoader from '../containers/layout/PageLoader';
import AdminLayout from '../containers/layout/AdminLayout';

function ListBoards(props) {
    const { boards, getBoards, dispatch } = useContext(BoardsContext);
    const history = useHistory();
    
    useEffect(() => {
        dispatch({
            type: 'SET_BOARDS_NOT_LOADED'
        });   
        async function fetchData() {
            await getBoards();
            if (boards.length === 0) {
                history.push('/departure-boards/create');
            }
        }
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])  //Run only on first mounted

    useEffect(() => {
        if (boards.boards.length === 0 && boards.isLoaded) {
            history.push('/departure-boards/create');
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boards]);  


    return (
        <PageLoader>
        <AdminLayout title="Your departure boards" subtitle="These are the departure boards you have setup">
            <Row className="justify-content-center">
                <Col xs={12} lg={8}>
                    <ListGroup className="mb-3">
                    {boards.boards ? boards.boards.map((board, boardKey) => {
                        return (
                            <ListGroup.Item key={boardKey}>
                                <div className="d-flex w-100 justify-content-between">
                                <div><span className="h3 mb-1 mr-2">{board.name}</span>
                                {board.routes && board.routes.length > 0
                                ? <Link to={'/departure-boards/' + board.code + '/edit'} target="_blank">View this board</Link>
                                : null}
                                </div>
                                <div> <Link to={'/departure-boards/' + board.code + '/edit'}><FontAwesomeIcon size="1x" icon={faEdit} /></Link></div>
                                </div>
                            </ListGroup.Item>
                        )
                    }):null}
                    </ListGroup>
                    <Link className="btn btn-md btn-primary" to={'/departure-boards/create'}>Create a new board</Link>
                </Col>
            </Row>
        </AdminLayout>
        </PageLoader>
    );
}

export default ListBoards;