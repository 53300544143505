// Core
import React, { useContext } from 'react';

// Libraries
import { useHistory } from "react-router-dom";
import { Row, Col, Form } from 'react-bootstrap';

// API's
import Board from "../../apis/Board";

// Utils
import { validateForm } from '../../utils/validation';

// Hooks
import useForm from '../../hooks/useForm';

// Contexts
import { BoardsContext } from '../../contexts/BoardsContext';

// Components
import TextInput from '../../components/TextInput';
import SubmitButton from '../../components/SubmitButton';

// CSS
import 'react-bootstrap-typeahead/css/Typeahead.css';

const rules = {
    name: [
        {
            name: 'required'
        },
    ]
};

function CreateBoardForm({ code }) {
    // Inital variables
    const initialValues = {
       name: ''
    }
    
    const initialTouched = {
        name: false
    }

    // Hooks
    const history = useHistory();
    const { dispatch: dispatchBoards } = useContext(BoardsContext);
    
    // Custom hook
    const route = async (values) => { return await Board.create(values);}
    const handleSuccess = async (handled) => {
        dispatchBoards({
            type: 'SET_BOARDS_NOT_LOADED'
        });
        history.push(`/departure-boards/${handled.data.code}/edit`);
    }

    const handleError = async (handled) => {
        //
    }
    
    const {
        values,
        // eslint-disable-next-line
        touched,
        errors,
        // eslint-disable-next-line
        response,
        isSubmitting,
        handleChange,
        handleSubmit
    } = useForm(
        route,
        initialValues,
        initialTouched,
        handleSuccess,
        handleError,
        validateForm,
        rules
    );

    return (
        <Form onSubmit={handleSubmit} noValidate>
            <Row>
                <Col xs={12} lg={8} className="form-group">
                    <TextInput
                        placeholder="Enter board name"
                        type="text"
                        label={<strong className="mr-2">Give your board a name</strong>}
                        name="name"
                        value={values.name} 
                        error={errors.name && errors.name.length > 0 ? errors.name[0] : null} 
                        help="For example my route to work"
                        handleChange={handleChange}
                    />
                </Col>
            </Row>
            <div className="form-group mb-0">
                <SubmitButton buttonText="Create board" loadingText="Loading" isLoading={isSubmitting}/>
            </div>
        </Form>
    );
}

export default CreateBoardForm;