// Core
import React from 'react';

// Libraries
import { Form, InputGroup } from 'react-bootstrap';

function TextInputGroup({ type, placeholder, label, name, value, error, handleChange }) {
    return (
        <>
            <Form.Label htmlFor={name} className="col-form-label">{label} </Form.Label>
            <InputGroup className="mb-2">
                    <Form.Control
                        placeholder={placeholder}
                        className={'form-control' + (error ? ' is-invalid' : '')}
                        type={type}
                        name={name}
                        onChange={handleChange}
                        value={value}
                        required
                        min={1}
                    />

                    <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">minutes</InputGroup.Text>
                    </InputGroup.Append>
                    {error
                    ? <Form.Control.Feedback type="invalid">
                        <strong>{error}</strong>
                    </Form.Control.Feedback> : null}

            </InputGroup>
            <Form.Text id="passwordHelpBlock" muted>
            If you know how long it takes you to get to the station we will let you know if you can make the train!
            </Form.Text>
        </>
    );
}

export default TextInputGroup;