// Core
import React, { useContext, useEffect } from 'react'

// Libraries
import { useHistory, useLocation, Link } from "react-router-dom";
import { Row, Col, Form } from 'react-bootstrap';

// API's
import User from "../apis/User";

// Utils
import { validateForm } from '../utils/validation';

// Hooks
import useForm from '../hooks/useForm';

// Contexts
import { AuthContext } from '../contexts/AuthContext';

// Containers
import AuthLayout from '../containers/layout/AuthLayout';

// Components
import TextInput from '../components/TextInput';
import SubmitButton from '../components/SubmitButton';
import Message from '../components/Message';

const rules = {
    email: [
        {
            name: 'required'
        },
        {
            name: 'email'
        }
    ],
    password: [
        {
            name: 'required'
        },
        {
            name: 'min',
            min: 3
        },
        {
            name: 'max',
            max: 255
        }
    ]
};

function Login() {

    // Inital variables
    const initialValues = {
		email: '',
		password: '',
    }
    
    const initialTouched = {
		email: false,
		password: false,
    }

    const successUrl = '/departure-boards';

    // Hooks
    const history = useHistory();
    const { auth, loginUser } = useContext(AuthContext);

    // Custom hook
    const route = User.login;
    const handleSuccess = async (response) => {
        await loginUser(response.data.user);
        history.push(successUrl);
    }

    const location = useLocation();

    useEffect(() => {
        if (auth.isLoggedIn) {
            history.push(successUrl);
        }
    }, [location, auth.isLoggedIn, history]);
    useEffect(() => {
        if (auth.isLoggedIn) {
            history.push(successUrl);
        }
    }, [auth.isLoggedIn, history]);

    const handleError = async (handled) => {
        //
    }

    const {
        values,
        // eslint-disable-next-line
        touched,
        errors,
        // eslint-disable-next-line
        response,
        message,
        isSubmitting,
        handleChange,
        handleSubmit
    } = useForm(
        route,
        initialValues,
        initialTouched,
        handleSuccess,
        handleError,
        validateForm,
        rules
    );

    if (auth.isLoggedIn) {
        //return <Redirect to="/home" />
    }
    
    return (
        <AuthLayout title="Login">
            <Form onSubmit={handleSubmit} noValidate>
                <Row>
                    <Col className="form-group">
                        <TextInput 
                            placeholder="Enter your email"
                            type="email"
                            label="Email"
                            name="email"
                            value={values.email} 
                            error={errors.email && errors.email.length > 0 ? errors.email[0] : null} handleChange={handleChange} />
                    </Col>
                </Row>
                <Row>
                    <Col className="form-group">
                        <TextInput
                            placeholder="Enter your password"
                            type="password"
                            label="Password"
                            name="password"
                            value={values.password}
                            error={errors.password && errors.password.length > 0 ? errors.password[0] : null}
                            handleChange={handleChange}
                        />
                    </Col>
                </Row>
                <div className="form-group">
                    <SubmitButton buttonText="Login" loadingText="Loading" isLoading={isSubmitting}/>
                    <Link className="btn btn-link" to="/forgot-password">Forgot your password</Link>
                </div>
            </Form>
        </AuthLayout>
    );
}

export default Login;