// Core
import React, { useContext } from "react";

// Libraries
import { Route, Redirect } from "react-router-dom";

// Contexts
import { AuthContext } from "../contexts/AuthContext";
import { AppContext } from "../contexts/AppContext";

function PrivateRoute({ component: Component, ...rest }) {
    const { app } = useContext(AppContext);
    const { auth } = useContext(AuthContext);
    const isLoggedIn = auth.isLoggedIn;
    return (
        <Route
            {...rest}
            render={props =>
                app.pageLoading || isLoggedIn ? (
                    <Component {...props} />
                ) : (
                        <Redirect to="/login" />
                    )
            }
        />
    );
}

export default PrivateRoute;