// Core
import React from 'react';

// Libraries
import { Form } from 'react-bootstrap';

function TextInput({ type, placeholder, label, name, value, error, handleChange, help }) {
    return (
        <>
            <Form.Label htmlFor={name} className="col-form-label">{label} </Form.Label>
                <Form.Control
                    placeholder={placeholder}
                    className={'form-control' + (error ? ' is-invalid' : '')}
                    type={type}
                    name={name}
                    onChange={handleChange}
                    value={value}
                    required
                    />
                {error
                ? <Form.Control.Feedback type="invalid">
                    <strong>{error}</strong>
                </Form.Control.Feedback> : null}

                { help
                ? <Form.Text className="help" muted>
                    {help}
                </Form.Text>
                : null}
        </>
    );
}

export default TextInput;