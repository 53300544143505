// Core
import { useContext } from 'react';

// Libraries
import { useHistory } from 'react-router-dom';

// Contexts
import { AuthContext } from '../contexts/AuthContext';

function withLogout(Component) {
    return function({ ...rest}) {
        const { logoutUser } = useContext(AuthContext);
        let history = useHistory();
        const logout = async () => {
            await logoutUser();
            history.push('/login')
        }
        return (
            <Component logout={logout}/>
        );
    };
}

export default withLogout;
