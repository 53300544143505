// Core
import React, { useContext } from 'react';

// Libraries
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, NavItem, Container } from 'react-bootstrap';

// Contexts
import { AuthContext } from '../contexts/AuthContext';

// Hoc
import withLogout from '../hoc/withLogout';

// Images
import map from '../image/map.svg';

const Navigation = (props) => {
    const { auth } = useContext(AuthContext);
    const isLoggedIn = auth.isLoggedIn && auth.user;
    let authMenu;
    if (isLoggedIn) {
        authMenu = <NavDropdown title={auth.user.name} id="basic-nav-dropdown">
            <NavDropdown.Item href="#" onClick={() => props.logout()}>Logout</NavDropdown.Item>
        </NavDropdown>;
    } else {
        authMenu = <>
            <NavItem eventkey={3} href="/login">
                <Nav.Link as={Link} to="/login">Login</Nav.Link>
            </NavItem>
            <NavItem eventkey={4} href="/register">
                <Nav.Link as={Link} to="/register">Register</Nav.Link>
            </NavItem>
        </>;
    }

    return (
        <Navbar expand="md" variant="light" bg="white" className="shadow-sm">
            <Container>
                <Nav.Link className="navbar-brand" as={Link} to="/">
                    <img alt="TrainBoards logo" src={map} title="TrainBoards logo" width="30" height="30" className="mr-2" />
                    Train<span className="text-primary">Boards</span>
                </Nav.Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        {isLoggedIn
                            ? <NavItem eventkey={3} href="/departure-boards">
                                <Nav.Link as={Link} to="/departure-boards">Your Departure Boards</Nav.Link>
                            </NavItem>
                            : null
                        }
                        {authMenu}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
};

export default withLogout(Navigation);