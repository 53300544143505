import Api from "./Api";
import Csrf from "./Csrf";

const BoardRoute = {
    async show(code) {
        return Api.get(`/boards/${code}`);
    },

    async create(code, form) {
        await Csrf.getCookie();

        return Api.post(`/boards/${code}/routes`, form);
    },

    async delete(routeId) {
        await Csrf.getCookie();

        return Api.post(`/routes/${routeId}`, { '_method': 'DELETE' });
    },
};

export default BoardRoute;
